import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import Error from "@material-ui/icons/Error"
import Home from "@material-ui/icons/Home"
import { AppBar, Footer, Layout, SEO } from "../components"

const Title = styled.h1`
  margin-top: 32px;
  font-size: 1.5rem;
  text-align: center;
  font-weight: 700;
  color: #607d8b;
`

const Text = styled.p`
  margin: 0;
  font-size: 1rem;
  text-align: center;
  color: #607d8b;
`

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: { textAlign: "center" },
    icon: {
      display: "block",
      fontSize: "8rem",
      lineHeight: 1,
      margin: "32px auto",
      color: "#cfd8dc",
    },
    button: { margin: "32px 0" },
  })
)

type Props = {}

const Component: React.FC<Props> = (props: Props) => {
  const classes = useStyles()

  return (
    <Layout>
      <SEO title="404 Not Found" />
      <AppBar />
      <Box mt={4}>
        <Container className={classes.container}>
          <Error className={classes.icon} />
          <Title>404 Not Found</Title>
          <Text>お探しのページは見つかりませんでした。</Text>
          <Button
            className={classes.button}
            component={Link}
            to="/"
            startIcon={<Home />}
            variant="contained"
          >
            トップへ戻る
          </Button>
        </Container>
      </Box>
      <Footer slug="/" copyright={{ publishDate: new Date() }} />
    </Layout>
  )
}

export default Component
